
import {defineComponent, ref, watch} from 'vue';
import {useStore} from 'vuex';
import useSpider from '@/components/spider/spider';
import useNode from '@/components/node/node';
import useProject from '@/components/project/project';
import Form from '@/components/form/Form.vue';
import FormItem from '@/components/form/FormItem.vue';
import InputWithButton from '@/components/input/InputWithButton.vue';
import CheckTagGroup from '@/components/tag/CheckTagGroup.vue';
import {TASK_MODE_SELECTED_NODE_TAGS, TASK_MODE_SELECTED_NODES} from '@/constants/task';
import pinyin, {STYLE_NORMAL} from 'pinyin';
import {isZeroObjectId} from '@/utils/mongo';

export default defineComponent({
  name: 'SpiderForm',
  components: {
    Form,
    FormItem,
    InputWithButton,
    CheckTagGroup,
  },
  setup() {
    // store
    const store = useStore();

    // use node
    const {
      allListSelectOptions: allNodeSelectOptions,
      allTags: allNodeTags,
    } = useNode(store);

    // use project
    const {
      allListSelectOptionsWithEmpty: allProjectSelectOptions,
    } = useProject(store);

    // use spider
    const {
      form,
    } = useSpider(store);

    // whether col field of form has been changed
    const isFormColChanged = ref<boolean>(false);

    const onColInput = () => {
      isFormColChanged.value = true;
    };

    watch(() => form.value?.name, () => {
      if (isFormColChanged.value) return;
      if (form.value?._id && isZeroObjectId(form.value?._id)) return;
      if (!form.value.name) {
        form.value.col_name = '';
      } else {
        const name = pinyin(form.value.name, {style: STYLE_NORMAL})
            .map(d => d.join('_'))
            .join('_');
        form.value.col_name = `results_${name}`;
      }
    });

    const onDataCollectionSuggestionSelect = ({_id}: { _id: string; value: string }) => {
      form.value.col_id = _id;
    };

    const onDataCollectionInput = (value: string) => {
      form.value.col_name = value;
      form.value.col_id = undefined;
    };

    return {
      ...useSpider(store),

      // custom
      TASK_MODE_SELECTED_NODES,
      TASK_MODE_SELECTED_NODE_TAGS,
      allNodeSelectOptions,
      allNodeTags,
      allProjectSelectOptions,
      onColInput,
      onDataCollectionSuggestionSelect,
      onDataCollectionInput,
    };
  },
});
