
import {defineComponent} from 'vue';
import CreateSpiderDialog from '@/components/spider/CreateEditSpiderDialog.vue';
import ListLayout from '@/layouts/ListLayout.vue';
import useSpiderList from '@/views/spider/list/spiderList';
import RunSpiderDialog from '@/components/spider/RunSpiderDialog.vue';

export default defineComponent({
  name: 'SpiderList',
  props: {
    noActions: {
      type: Boolean,
    }
  },
  components: {
    RunSpiderDialog,
    ListLayout,
    CreateSpiderDialog,
  },
  setup() {
    const {
      navActions,
      tableColumns,
      tableData,
      tableTotal,
      tablePagination,
      actionFunctions,
      tableActionsPrefix,
      activeDialogKey,
    } = useSpiderList();

    return {
      navActions,
      tableColumns,
      tableData,
      tableTotal,
      tablePagination,
      actionFunctions,
      tableActionsPrefix,
      activeDialogKey,
    };
  },
});
