
import {defineComponent} from 'vue';
import {useStore} from 'vuex';
import CreateEditDialog from '@/components/dialog/CreateEditDialog.vue';
import SpiderForm from '@/components/spider/SpiderForm.vue';
import useSpider from '@/components/spider/spider';

export default defineComponent({
  name: 'CreateSpiderDialog',
  components: {
    CreateEditDialog,
    SpiderForm,
  },
  setup() {
    // store
    const store = useStore();

    return {
      ...useSpider(store),
    };
  },
});
